<!-- 竹藤展览 -->
<template>
  <div class="exhibition">
    <el-row>
      <el-col :span="24" :lg="24">
        <div class="pageBox">
          <div class="header pc">
            <el-image
              style="width: 100%"
              :src="require('../../assets/images/exhibition/banner.png')"
              :fit="'fill'"
            >
            </el-image>
            <div class="title" :class="{ weibei: lang }">
              {{ $t("bambooExhibition") }}
            </div>
          </div>
          <div class="header h5" :class="{ weibei: lang }">
            <img
              src="@/assets/images/exhibition/banner-h5.png"
              alt=""
              style="width: 100%; height: 100%; object-fit: cover"
            />
            <div class="title">{{ $t("bambooExhibition") }}</div>
          </div>
          <div class="lcontainer container" v-if="0">
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("introduction") }}
              </div>
              <p>{{ $t("bambooExhibitionP1") }}</p>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP2") }}
              </div>
              <div class="tab-box">
                <!-- <div class="m-b-24 g-color" style="font-size:20px;font-weight: 700;">时间表</div> -->
                <!-- <el-image style="width: 100%" :src="tableImg" :fit="'fill'">
                                </el-image> -->
                <table border="1" cellspacing="0" class="exhTable">
                  <tbody>
                    <tr
                      style="
                        background: #8dc32b;
                        color: #fff;
                        height: 60px;
                        font-weight: 700;
                      "
                    >
                      <td valign="center" style="width: 10%">
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h1") }}</span>
                        </p>
                      </td>
                      <td valign="center" style="width: 40%">
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h2") }}</span>
                        </p>
                      </td>
                      <td valign="center" style="width: 11%">
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h3") }}</span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span>{{ $t("exhibitionTable.h4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.a1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.a2") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span>
                            {{ $t("exhibitionTable.a3") }}
                          </span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.a4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b2") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b3") }}</span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.b4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.c1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span v-html="$t('exhibitionTable.c2')"> </span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span v-html="$t('exhibitionTable.c3')"></span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.c4") }}</span>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td valign="center" height="30">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d1") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d2") }}</span>
                        </p>
                      </td>
                      <td valign="center">
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d3") }}</span>
                        </p>
                      </td>
                      <td
                        valign="center"
                        :style="{ display: lang ? '' : 'none' }"
                      >
                        <p align="center">
                          <span> {{ $t("exhibitionTable.d4") }}</span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP3") }}
              </div>
              <p class="m-b-14">{{ $t("bambooExhibitionP4") }}</p>
              <div class="pc" style="width: 699px; margin: 0 auto">
                <el-image style="width: 100%" :src="cImg1" :fit="'fill'">
                </el-image>
              </div>
              <div class="h5">
                <el-image style="width: 100%" :src="cImg1" :fit="'fill'">
                </el-image>
              </div>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP5") }}
              </div>
              <p class="m-b-14">{{ $t("bambooExhibitionP6") }}</p>
              <p class="m-b-14">{{ $t("bambooExhibitionP7") }}</p>
              <div class="p-indent">
                <p class="flex a-c m-b-14">{{ $t("bambooExhibitionP8") }}</p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP9") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP10") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP11") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP12") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP13") }}
                </p>
                <p class="flex a-c m-b-14">
                  <span class="dot"></span> {{ $t("bambooExhibitionP14") }}
                </p>
              </div>
              <div class="m-b-34">
                {{ $t("bambooExhibitionP15") }}
              </div>
              <div class="pc" style="width: 699px; margin: 0 auto">
                <el-image style="width: 100%" :src="cImg2" :fit="'fill'">
                </el-image>
              </div>
              <div class="h5">
                <el-image style="width: 100%" :src="cImg2" :fit="'fill'">
                </el-image>
              </div>

              <p class="m-t-60 m-b-20">
                {{ $t("bambooExhibitionP16") }}
              </p>
              <p class="m-b-20">{{ $t("bambooExhibitionP17") }}</p>
              <p>{{ $t("bambooExhibitionP18") }}</p>
            </div>

            <div class="subtitle-box" v-if="0">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP19") }}
              </div>
              <!-- <p>请于2022年8月30日前，<span class="a-color">发送参展回执</span> 至大会展览组李婷女士 （ <span
                                    class="g-color">tli@inbar.int</span> ）。</p> -->
              <p v-html="$t('bambooExhibitionP20')" @click="openUrl"></p>
            </div>

            <div class="subtitle-box">
              <div class="subtitle" :class="{ weibei: lang }">
                {{ $t("bambooExhibitionP21") }}
              </div>
              <!-- <p class="flex a-c m-b-14"> <span class="dot"></span> 国际竹藤组织东道国部主任：傅金和博士</p> -->
              <p class="flex a-c m-b-14" v-html="$t('bambooExhibitionP22')"></p>
              <p class="m-b-14 text-indent-20 pc">
                <span v-html="$t('bambooExhibitionP23')"></span>
                <span v-html="$t('bambooExhibitionP24')"></span>
              </p>
              <p
                class="m-b-14 h5 text-indent-20"
                v-html="$t('bambooExhibitionP23')"
              ></p>
              <p
                class="m-b-14 h5 text-indent-20"
                v-html="$t('bambooExhibitionP24')"
              ></p>
              <p class="flex m-b-14">
                <span class="dot m-t-8"></span>
                <span v-html="$t('bambooExhibitionP25')"></span>
              </p>
              <p class="text-indent-20 pc">
                <span v-html="$t('bambooExhibitionP26')"></span>
                <span v-html="$t('bambooExhibitionP27')"></span>
                <span></span>
              </p>
              <p
                class="h5 text-indent-20 m-b-14"
                v-html="$t('bambooExhibitionP26')"
              ></p>
              <p
                class="h5 text-indent-20"
                v-html="$t('bambooExhibitionP27')"
              ></p>
            </div>
          </div>
          <div class="newPages" v-if="1">
            <!-- <div class="item" @click="goDetails(1)">
              {{ $t("exh").zuzi.title }}
            </div> -->
            <div
              class="item"
              @click="goDetails(0, index)"
              v-for="(item, index) in $t('exh').list"
              :key="index"
            >
              <p v-if="item.t1">{{item.t1}}</p>
              <p v-if="item.t2">{{item.t2}}</p>
              <span v-else>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableImg: null,
      cImg1: null,
      cImg2: null,

    };
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  created() {},
  methods: {
    goDetails(type, index) {
      this.$router.push({
        path: "/exhibitionDetails",
        query: { type: type, i: index },
      });
    },
  },
};
</script>
<style scoped>
</style>
<style lang="stylus">
.exhibition {
  .pageBox {
    .header {
      .el-image {
        display: block;
      }
    }
  }
}
</style>
<style lang="stylus" scoped>
@media screen and (min-width: 728px) {
  .newPages {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    max-width: 1400px;
    padding: 60px 0 40px;
    box-sizing: border-box;
    margin: auto;
  }

  .newPages .item {
    width: 212px;
    height: 70px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center
    font-size: 18px;
    font-weight: bold
    color: #ffffff;
    background-color: #405e3c;
    background-image: url('../../assets/images/exhibition/itemback.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    line-height: 1
  }

  .newPages .item:hover {
    background-image: url('../../assets/images/exhibition/hv.png');
  }

  .exhibition {
    background: #fafafa;
    width: 100%;
    overflow: hidden;

    table, td, th {
      border: 0.5px solid #f2f2f2;
    }

    p, div {
      line-height: 1.5;
    }

    .pageBox {
      .h5 {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          padding: 0 20px;
          height: 80px;
          line-height: 80px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 70px;
        }
      }

      .container {
        width: 100%;
        max-width: 1520px;
        margin: auto;
        padding: 26px 60px 97px 60px;
        text-align: left;
        box-sizing: border-box;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #405E3C;
          text-align: center;
        }

        .ctitle {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #8DC63F;
          text-align: center;
          margin-top: 30px;
        }

        .ctime {
          color: #606266;
          text-align: center;
          margin-top: 24px;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 28px;
            color: #405E3C;
            margin: 43px 0 29px 0;
            font-weight: 700;
          }

          .secondarytitle {
            font-size: 20px;
            margin-bottom: 22px;
            font-weight: 700;
          }
        }

        .tab-box {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }

  .exhTable {
    width: 100%;

    td {
      height: 30px;
    }

    tr {
      height: 60px;
    }
  }
}

@media screen and (max-width: 728px) {
  .newPages {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    width 100%;
    padding: 20px 10px;
    box-sizing: border-box;
  }

  .newPages .item {
    width: 49%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #ffffff;
    background-color: #405e3c;
    background-image: url('../../assets/images/exhibition/itemback.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    flex-wrap: wrap;
  }

  .newPages .item:hover {
    background-image: url('../../assets/images/exhibition/hv.png');
  }

  .exhibition {
    width: 100%;
    overflow: hidden;

    p, div {
      line-height: 1.5;
    }

    .pageBox {
      .pc {
        display: none;
      }

      .header {
        position: relative;
        overflow: hidden;
        height: 190px;
        width: 100%;

        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #fff;
          width: 164px;
          height: 60px;
          line-height: 60px;
          transform: translate(-50%, -50%);
          text-align: center;
          background: rgba(141, 198, 63, 0.9);
          font-weight: 700;
          font-size: 22px;
        }
      }

      .container {
        padding: 20px;
        text-align: left;

        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #405E3C;
          text-align: center;
        }

        .ctitle {
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          color: #8DC63F;
          text-align: center;
          margin-top: 30px;
        }

        .ctime {
          color: #606266;
          text-align: center;
          margin-top: 24px;
        }

        .subtitle-box {
          text-align: left;

          .subtitle {
            font-size: 20px;
            color: #405E3C;
            // margin: 43px 0 29px 0;
            margin: 15px 0;
            font-weight: 700;
          }

          .secondarytitle {
            font-size: 20px;
            margin-bottom: 22px;
            font-weight: 700;
          }
        }

        .tab-box {
          font-size: 12px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }

    .exhTable {
      width: 100%;
    }
  }
}
</style>